import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { IUser, ResponseJson } from '../models/model';
import { BaseService } from './base.service';
import { environment } from '../../../environments/environment.dev';
import { jwtDecode } from "jwt-decode";

export interface ILogin {
    token: string,
    refreshToken: string
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends BaseService {

    apiUrl = environment.apiUrl + '/authentication';
    token = localStorage.getItem('token')
    public currentUserSubject = new BehaviorSubject<any>(this.token);
    public currentUser: Observable<IUser | undefined | null>;
    private tokenExpiredSubject: BehaviorSubject<boolean>;
    public tokenExpired!: Observable<boolean>;

    constructor(
        protected override http: HttpClient,
        private router: Router
    ) {
        super(http);
        this.currentUserSubject = new BehaviorSubject<IUser | undefined | null>(this.getUser());
        this.currentUser = this.currentUserSubject.asObservable();
        this.tokenExpiredSubject = new BehaviorSubject<boolean>(this.isRefreshTokenExpired());
        this.tokenExpired = this.tokenExpiredSubject.asObservable();
    }


    public login(data: { username: string, password: string, builder?: string }) {
        return this.http.post<any>(this.apiUrl + '/login', data).pipe(map(data => {
            return this.setUserData(data.data)
        }))
    }

    setUserData(data: ILogin | Partial<ILogin>) {
        let user = null;
        // login successful if there's a jwt token in the response
        if (data.token) {
            user = this.getPayLoad(data.token);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('token', data.token);
            localStorage.setItem('refreshToken', data.token);
            this.currentUserSubject.next(user);
            this.tokenExpiredSubject.next(false);
        }
        return user;
    }

    public getPayLoad(token: string) {
        const decoded = jwtDecode(token) as any;
        return decoded;
    }

    logout(needRedirect: boolean = true) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        this.currentUserSubject.next(null)
        if (needRedirect) {
            this.router.navigate(['/login']);
        }
    }

    signUp(data: FormData) {
        return this.post<any>(this.apiUrl + '/sign-up', data).pipe(map(data => {
            return this.setUserData(data.data)
        }))
    }


    sendOTP(email: string) {
        return this.post(this.apiUrl + '/send-otp', { email })
    }
    checkOrganization(name: string) {
        return this.post(this.apiUrl + '/check-organization', { name })
    }

    verifyOTP(email: string, otp: string) {
        return this.post(this.apiUrl + '/verify-otp', { email, otp })
    }
    sendVerificationEmail(email: string) {
        return this.post(this.apiUrl + '/forgot-password', { email })
    }

    resetPassword(userId: string, token: string, newPassword: string) {
        return this.post(this.apiUrl + '/reset-password', { userId, token, newPassword })
    }

    validateToken(token: string, userId: string) {
        return this.post(this.apiUrl + '/validate-token', { token, userId })
    }

    public getUser(): IUser | undefined {
        const token = localStorage.getItem('token');
        if (token) {
            const user = this.getPayLoad(token);
            return user;
        }
        return undefined;
    }

    isRefreshTokenExpired(token?: string): boolean {
        // for refresh token
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            return true;
        }
        return false;
    }

    userSignUpFromInvitation(data: FormData) {
        return this.post<ILogin>(this.apiUrl + '/invitation-signup', data);
    }

    checkForOtp(email: string) {
        return this.post(this.apiUrl + '/check-for-otp', { email })
    }

    // Get anonymous ID, ensuring synchronous flow
    async generateAnonymousId(id: string, shareUserId: string | null, contractId: string): Promise<any> {
        const data = await firstValueFrom(this.post<ILogin>(`${this.apiUrl}/anonymous-token`, {
            id,
            shareUserId,
            contractId
        }));
        return this.setUserData(data.data);
    }

    checkUsername(username: string) {
        return this.get(this.apiUrl + '/check-username/' + username);
    }

    updateEmail(updateEmailToken: string) {
        return this.post(this.apiUrl + '/update-email', { updateEmailToken })
    }

}