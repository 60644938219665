import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

interface CustomMessage extends Message {
    onClick?: () => void;
}

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private messageService: MessageService) { }

    success(detail: string, summary: string = 'Success!', onClick?: () => void) {
        const message: CustomMessage = {
            severity: 'success',
            summary,
            detail,
            life: 3000,
            closable: true,
            onClick: onClick
        };
        this.messageService.add(message);
    }

    error(detail: string, summary: string = 'Error!', onClick?: () => void) {
        const message: CustomMessage = {
            severity: 'error',
            summary,
            detail,
            life: 3000,
            closable: true,
            onClick: onClick
        };
        this.messageService.add(message);
    }

    warn(detail: string, summary: string = 'Warning!', onClick?: () => void) {
        const message: CustomMessage = {
            severity: 'warn',
            summary,
            detail,
            life: 3000,
            closable: true,
            onClick: onClick
        };
        this.messageService.add(message);
    }

    info(detail: string, summary: string = 'Info!', onClick?: () => void) {
        const message: CustomMessage = {
            severity: 'info',
            summary,
            detail,
            life: 3000,
            closable: true,
            onClick: onClick
        };
        this.messageService.add(message);
    }
}